<template>
  <section class="billboard-main center no-padding">
    <div class="billboard-main__puff text-color--invert text-align--left">
      <h3 class="title">Hello! I'm Ebba.</h3>
      <p class="text">Your career bot, specialised in tech recruitment. Are you ready to start a new chapter?</p>
      <base-button @click.native="EbbaModal" large>Chat with me</base-button>
    </div>
    <div class="billboard-main__media-wrapper">
        <!-- <img src="@/assets/ebba-placeholder-2.jpg" alt="" class="billboard-main__placeholder center" /> -->
        <video playsinline autoplay muted> 
        <!-- <source src="video-small.mp4" type="video/mp4" media="all and (max-width: 480px)"> 
        <source src="video-small.webm" type="video/webm" media="all and (max-width: 480px)">  -->
            <source src="@/assets/video/ebba1-1600x1600-15Mbps.mp4" type="video/mp4"> 
            <source src="@/assets/video/ebba1-1600x1600-70CQ.webm" type="video/webm">
        </video>
    </div>
  </section>
</template>
<script>
import ModalMixin from '@/mixins/modal'

export default {
  mixins: [ModalMixin],
  components: {},
  data() {
    return {};
  },
  mounted() {
    const video = document.querySelector('video');
    video.addEventListener('loadeddata', (event) => {
        this.$store.dispatch('app/LOADED')        
    });
  }
};
</script>
<style lang="scss">
.billboard-main {
    --billboard-media-width: 600px;
    max-width: var(--billboard-media-width);
    position: relative;
    height: 450px;
    @include sm {
        height: 600px;
    }
    &__puff {
        background-color: var(--brand-color-1);
        border-radius: var(--border-radius-default);
        padding: var(--padding-default);
        width: 85%;
        max-width: 500px;
        position: absolute;
        bottom: -15%;
        left: 50%;
        transform: translateX(-50%) translateY(9vh);
        opacity: .92;
        
        .title {
            font-size: 2rem;
            margin-bottom: 1.2rem;
            font-weight: 500;
        }
        .text {
            margin-bottom: 1.5rem;
        }
    }
    &__media-wrapper{
        @include sm {
            height: 100%;
        }
        img, video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // opacity: .2
        }
    }
}
</style>
