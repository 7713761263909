import EventBus from '@/includes/event-bus'
import ChatBot from '@/components/chat-bot/chat-bot.vue'
import PrivacyPolicy from '@/router/pages/Privacy.vue'

export default {
    methods: {
        EbbaModal(properties) {
            EventBus.$emit('MODAL_OPEN', {
                component: ChatBot,
                title: 'Ebba: Your Career Bot'
            })
        },
        PrivacyModal() {
            EventBus.$emit('MODAL_OPEN', {
                component: PrivacyPolicy,
                title: 'Privacy Policy',
                header: true,
                size: 'large',
            })
        },
    }
}